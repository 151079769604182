import './App.css';
import { useEffect, useState } from 'react';
import { GoogleMap, MarkerF, useLoadScript } from '@react-google-maps/api';
import 'bootstrap/dist/css/bootstrap.min.css';
import Carousel from 'react-bootstrap/Carousel'; 
import { Badge, Dropdown, Form, Stack } from 'react-bootstrap';

function App() {

  // set up state variables to use
  const [allHikes, setAllHikes] = useState([]);
  const [displayHikes, setDisplayHikes] = useState([]);
  const [selectedHike, setSelectedHike] = useState("");
  const [coordinates, setCoordiantes] = useState({ lat: 47.237045, lng: -120.609451 });
  const [numWeeks, setNumWeeks] = useState(4);

  // load all of the hikes from the server
  useEffect(() => {
    fetch("https://hikesnowfree.onrender.com/api/returnHikes", {method: "POST", headers: {"Content-Type": "application/json"}})
    .then(processResponse)
    .catch(() => console.error("Error: failed to connect to server"));
  }, []);

  function processResponse(res) {
    res.json().then(function(response) {
      setDisplayHikes(response.value);
      setAllHikes(response.value);
      setSelectedHike(response.value[0]?.name_link || '');
    })
    .catch(() => console.error("response is not a valid JSON"));
  }
  
  // setup details for the map
  const mapContainerStyle = {
    width: '100%',
    height: '80vh',
  };
  const options = {
    disableDefaultUI: true,
    mapTypeId: 'roadmap',
    restriction: {
      latLngBounds: {
        north: 49.702171,
        south: 44.637272,
        east: -114.717503,
        west: -128.371671,
      },
    },
    gestureHandling: 'greedy'
  };

  // load the map onto the page
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyCyGcOrWYorqJJ0jMnoqiGgvPDSgHvnzsM',
  });
  if (loadError) {
    return <div>Error loading Google Maps</div>;
  }
  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  // render markers onto the map highlighting hikes
  function renderMarkers () {
    
    // return nothing if not loaded yet
    if (displayHikes.length === 0) {
      return <div></div>
    }
    
    return displayHikes.map((hike, index) => {
      
      // make blue if snow is found, brown if not
      let pic = "browncircle.png";
      for (const review of hike.reviews) {
        if (review.snow) {
          pic = "bluecircle.png";
          break;
        }
      }

      // make orange if it's currently selected
      if (hike.name_link === selectedHike) {        
        pic = "orangecircle.png";
      }

      return (
        <MarkerF
        key={`${index}-${selectedHike}`}
        position={{lat: parseFloat(hike.latitude), lng: parseFloat(hike.longitude)}}
        onClick={() => setSelectedHike(hike.name_link)}
        icon={{ url:pic, scaledSize: new window.google.maps.Size(18, 18) }}
        />
      );
    });
  };

  function renderPhotos(reviews) {
    

    // extract all the photo links for reviews
    let reviews_copy = [];
    for (const review of reviews) {
      if (review.photos.length !== 0) {
        reviews_copy.push(review);
      }
    }

    if (reviews_copy.length == 0) {
      return <div></div>;
    }
    
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 200 }}>
        <Carousel>
          {reviews_copy.map((review, index) => (
            <Carousel.Item key={index} interval={3000}>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                <img
                  className="d-block"
                  src={review.photos[0]}
                  alt={`Image ${index + 1}`}
                  style={{ maxHeight: '200px', maxWidth: '100%' }}
                />
              </div>
              <Carousel.Caption>
                <p>{`${review.month}/${review.day}/${review.year}`}</p>
              </Carousel.Caption>
            </Carousel.Item>
          ))}
        </Carousel>
      </div> 
    );
  }

  // render information for the currently selected hike
  function renderHikeInfo() {
    
    // return nothing if no currently selected hike
    if (selectedHike === "") {
      return <div></div>
    }

    // find the information for selected hike
    const hike = displayHikes.find(hike => hike.name_link === selectedHike);
    const link = `https://www.wta.org/go-hiking/hikes/${hike.name_link}`;

    // calculate the number of reviews & snow mentions
    const numReviews = hike.reviews.length;
    let snowCount = 0;
    for (const review of hike.reviews) {
      if (review.snow) {
        snowCount += 1;
      }
    }

    return (    
      <div className="row">
        {renderPhotos(hike.reviews)}
        <h2 className ="col-12 pb-2">
          <a href={link} target ="_blank" rel="noopener noreferrer">{hike.name}</a>
        </h2>
        <Stack direction="horizontal" gap={3}>
        {hike.length && (
                <Badge pill bg="success" className="mb-2">Roundtrip Length: {hike.length} mi</Badge>
            )}
        {hike.high_point && (
                <Badge pill bg="success" className="mb-2">Highest Point: {hike.high_point} ft</Badge>
        )}
        {hike.elev_gain && (
                <Badge pill bg="success" className="mb-2">Elevation Gain: {hike.elev_gain} ft</Badge>
        )}
        </Stack>
        <h4 className="col-12 mt-3">
          <p>There {renderNumReviews(numReviews)} in the past {renderWeeks()}. {Math.round(snowCount / numReviews * 100)}% of them mark the hike as containing snow</p>
        </h4>
        <div className="container mt-4" style={{ maxHeight: '55vh', overflowY: 'auto' }}>
          {hike.reviews.map((review, index) => (
            <div key={index} className="pb-3">
              {renderReviewDate(review)}
              <div dangerouslySetInnerHTML={{ __html: review.text }} />
            </div>
          ))}
        </div>
      </div>
    );
  }

  function handleWeeksChanged(eventKey) {
    // calculate which hikes we need to display based off # of weeks

    setNumWeeks(parseInt(eventKey));
    const allHikesEdited = [];
    for (let hike of allHikes) {
      let newReviews = [];
      for (const review of hike.reviews) {
        const reviewDate = new Date(review.year, review.month - 1, review.day);
        const cutoff = new Date();
        cutoff.setDate(cutoff.getDate() - (eventKey * 7));
        if (reviewDate >= cutoff) {
          newReviews.push(review);
        }
      }
      if (newReviews.length !== 0) {
        hike.reviews = newReviews;
        allHikesEdited.push(hike);
      }
    }
    setSelectedHike(allHikesEdited[0].name_link);
    setDisplayHikes(allHikesEdited);
  }

  // updates current map center whenever user moves the map
  function handleCenterChanged (map) {
    const newCenter = map.getCenter();
    setCoordiantes({lat: newCenter.lat(), lng: newCenter.lng()});
  };

  // Function to handle the selection of a suggested option
  const handleSuggestionClick = (suggestion) => {
    setSelectedHike(suggestion.name_link);
  };

  const handleChange = (event) => {
    const inputValue = event.target.value;
    const selectedHike = displayHikes.find(hike => hike.name === inputValue);

    if (selectedHike) {
      handleSuggestionClick(selectedHike);
    }
  };

  function renderWeeks() {
    if (numWeeks === 1) {
      return "1 week";
    }
    return `${numWeeks} weeks`;
  }

  function renderNumReviews(numberReviews) {
    if (numberReviews === 1) {
      return "is 1 review";
    }
    return `are ${numberReviews} reviews`;
  }

  function renderReviewDate(review) {

    // make blue if snow is found, brown if not
    let pic = "browncircle.png";
    if (review.snow) {
      pic = "bluecircle.png";
    }
    
    return (
      <Stack direction="horizontal" gap={2} align="center">
        <h4>{`${review.month}/${review.day}/${review.year}`}</h4>
        <img src={pic} alt="blue circle" style={{width: '25px', height: '25px'}}></img>
      </Stack>
    );
  }

  return (
    <div className="container-fluid">
      {/* Title Section */}
      <div className="row mt-3 mb-3">
        <div className="col-12 text-center">
          <h1>SnowFreeHikes - Washington</h1>
        </div>
      </div>

      {/* Website Description Section */}
      <div className="row">
        <div className="col-12">
          <div className="website-description">
            <h2>Discover all hikes that don't require winter/snow gear!</h2>
            <p className="lead">
              Reviews are sourced from {' '}
              <a href="https://www.wta.org" target="_blank" rel="noopener noreferrer">
                wta.org
              </a>. They are not guaranteed to be accurate but provide excellent information on what hikes are feasable during shoulder season. Reviews are refreshed daily so make sure to check frequently! Feel free to give feedback or ask questions <a href="mailto:mrakickas@gmail.com">here</a>. 
            </p>
          </div>
        </div>
      </div>

      <div className="mx-4">
        <Stack direction="horizontal" gap={2}>
              <img className="mr-3" src="bluecircle.png" alt="blue circle" style={{width: '30px', height: '30px'}}></img>
              <p>Blue circles represents hikes WITH snow mentioned in recent reviews</p>
        </Stack>
      </div>
      <div className="mx-4">
        <Stack direction="horizontal" gap={2}>
            <img className="mr-3" src="browncircle.png" alt="brown circle"style={{width: '30px', height: '30px'}}></img>
            <p>Brown circles represents hikes WITHOUT snow mentioned in recent reviews</p>
        </Stack>
      </div>

      <div className="mx-4">
        <Stack direction="horizontal" gap={2}>
            <img className="mr-3" src="orangecircle.png" alt="orange circle"style={{width: '30px', height: '30px'}}></img>
            <p>Orange circles represents the currently selected hike</p>
          </Stack>

      </div>

      <Stack direction="horizontal" gap={2}>
        <p>Showing data for the past </p>
        <Dropdown onSelect={handleWeeksChanged}>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              {renderWeeks()}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item eventKey={1}>1 week</Dropdown.Item>
              <Dropdown.Item eventKey={2}>2 weeks</Dropdown.Item>
              <Dropdown.Item eventKey={3}>3 weeks</Dropdown.Item>
              <Dropdown.Item eventKey={4}>4 weeks</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <p>{'<---- tinker with this!'}</p>
      </Stack>
      <p>Click on any hike below to get started!</p>

      <Form.Group className="mb-3">
        <Form.Control
          type="text"
          className="form-control"
          onChange={handleChange}
          placeholder="Search for specific hikes..."
          list="hike-list"
          id="search-input"
        />
        <datalist id="hike-list">
          {displayHikes.map((hike, index) => (
            <option key={index} value={hike.name} />
          ))}
        </datalist>
      </Form.Group>

      {/* Split Screen Section */}
      <div className="row">
        {/* Left side */}
        <div className="col-md-6 bg-dark text-light p-4">
          <div className="centered">
            <GoogleMap
              zoom={7}
              center={coordinates}
              mapContainerStyle={mapContainerStyle}
              options={options}
              onLoad={(map) => {
                map.addListener('on-drag', () => handleCenterChanged(map));
              }
            }
            >
              {renderMarkers()}
            </GoogleMap>
          </div>
        </div>

        {/* Right side */}
        <div className="col-md-6 bg-white p-4">
          {/* Your content for the right side goes here */}
          {renderHikeInfo()}
        </div>
      </div>
    </div>
  );
}

export default App;
